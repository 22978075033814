import {
  themeDefaultColor,
  otherThemeColors,
  rgba,
  SPL_EVENT,
  openmind_sme,
  dayLightSaving
} from '../constants/app';
import { logo } from '../images';
import querystring from 'querystring';
import linkifyUrls from 'linkifyjs/html';
import { step1Initial } from 'containers/SME/Register/Step1/constants';
import { step2Initial } from 'containers/SME/Register/Step2/constants';
import { step3Initial } from 'containers/SME/Register/Step3/constants';
import cs from 'classnames';

export const getStorageData = key => {
  try {
    return JSON.parse(window.localStorage.getItem(key)) || {};
  } catch (err) {
    return {};
  }
};

export const setStorageData = (key, data) => {
  const dataTemp = JSON.stringify(data);
  window.localStorage.setItem(key, dataTemp);
};

export const clearStorageData = key => window.localStorage.removeItem(key);

export const getSessionData = key => {
  return JSON.parse(window.sessionStorage.getItem(key)) || {};
};

export const setSessionData = (key, data) => {
  const dataTemp = JSON.stringify(data);
  window.sessionStorage.setItem(key, dataTemp);
};

export const clearSessionData = key => window.sessionStorage.removeItem(key);

export const calculateArrayField = (items, field) => {
  return items.reduce((a, b) => {
    return a + b[field];
  }, 0);
};

export const getFileExtension = fileName => {
  return fileName !== undefined
    ? fileName
        .toLowerCase()
        .split('.')
        .pop()
    : null;
};

function lastSunday(year, month) {
  var date = new Date(year, month, 1, 12);
  let weekday = date.getDay();
  let dayDiff = weekday === 0 ? 7 : weekday;
  let lastSunday = date.setDate(date.getDate() - dayDiff);
  return date.toDateString();
}

export const formatDate = date => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const checkDayLightSaving = date => {
  var currentData = date ? new Date(date) : new Date();
  var daylightstart = lastSunday(
    currentData.getFullYear(),
    dayLightSaving.STARTMONTH
  );
  var daylightEnd = lastSunday(
    currentData.getFullYear(),
    dayLightSaving.ENDMONTH
  );

  return formatDate(daylightstart) <= formatDate(currentData) &&
    formatDate(daylightEnd) >= formatDate(currentData)
    ? 'BST'
    : 'GMT';
};

export const getType = type => {
  if (typeof type === 'string') {
    switch (type.toLowerCase()) {
      case 'jpeg':
      case 'jpg':
      case 'png':
        return 'images';
      case 'ppt':
      case 'pptx':
      case 'docx':
      case 'pdf':
      case 'doc':
      case 'xls':
      case 'xlsx':
      case 'xlsm':
      case 'csv':
        return 'files';
      case 'mov':
      case 'MOV':
      case 'mp4':
      case 'MP4':
        return 'videos';
      default:
        return null;
    }
  }
  return null;
};

export function xmlToJson(xml) {
  // Create the return object

  let obj = {};
  let i;
  let j;
  let attribute;
  let item;
  let old;

  if (xml.nodeType === 1) {
    // element
    // do attributes

    if (xml.attributes.length > 0) {
      obj['@attributes'] = {};
      for (j = 0; j < xml.attributes.length; j += 1) {
        attribute = xml.attributes.item(j);
        obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
      }
    }
  } else if (xml.nodeType === 3) {
    // text

    obj = xml.nodeValue;
  }

  // do children

  if (xml.hasChildNodes()) {
    for (i = 0; i < xml.childNodes.length; i += 1) {
      item = xml.childNodes.item(i);
      const { nodeName } = item;
      if (obj[nodeName] === undefined) {
        obj[nodeName] = xmlToJson(item);
      } else {
        if (obj[nodeName].push === undefined) {
          old = obj[nodeName];
          obj[nodeName] = [];
          obj[nodeName].push(old);
        }
        obj[nodeName].push(xmlToJson(item));
      }
    }
  }
  return obj;
}

export function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 1024;
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  const totalLength = byteCharacters.length;
  for (let offset = 0; offset < totalLength; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const sliceLength = slice.length;
    const byteNumbers = new Array(sliceLength);
    for (let i = 0; i < sliceLength; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
}

export const selectedThemeColor = () => {
  const user = getStorageData('user');
  const localColors = user.colors ? JSON.parse(user.colors) : {};
  const {
    mainColor,
    extraColor,
    secondaryColor,
    backgroudColor,
    buttonColor
  } = themeDefaultColor;
  return {
    mainColor: localColors.mainColor || mainColor,
    extraColor: localColors.extraColor || extraColor,
    secondaryColor: localColors.secondaryColor || secondaryColor,
    backgroudColor: localColors.backgroudColor || backgroudColor,
    buttonColor: localColors.buttonColor || buttonColor,
    logoImage: user.profile_picture || logo,
    otherThemeColors,
    rgba
  };
};

export const imageToBase64 = URL => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.addEventListener('load', function() {
      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);
      try {
        resolve(canvas.toDataURL('image/png'));
      } catch (err) {
        reject(err);
      }
    });
    image.src = URL;
  });
};

export const currentActivePage = () => {
  const pathName = window.location.pathname.split('/').filter(value => {
    if (value.length > 0) {
      return value;
    }
  });
  return pathName.length > 1 ? pathName[1] : pathName[0];
};

export const hexToRgb = hex => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : null;
};

export const setColorChanges = value => {
  window.localStorage.setItem('colorChange', value);
};
export const getChanges = () => {
  return JSON.parse(window.localStorage.getItem('colorChange'));
};

export const parseQueryString = (value, variable = '?current-view') => {
  if (!value) return null;
  const q = querystring.parse(value);
  const removeHash = q[variable].split('#');
  return removeHash[0];
};

const createTime = function(end) {
  const hrs = [];
  for (let i = 0; i < end; i += 1) {
    hrs.push({ key: i, text: i, value: i });
  }
  return hrs;
};

export const timeDropDown = {
  hrs: createTime(24),
  mins: createTime(60)
};

export const subString = (str, length) => {
  if (str === null) return '';
  if (str && str.length > length) return `${str.substring(0, length)}...`;
  return str;
};

export const dateFormate = date => {
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const currentDate = newDate.getDate() + 1;
  const currentMonth = newDate.getMonth() + 1;
  const day = currentDate < 10 ? `0${currentDate}` : currentDate;
  const month = currentMonth < 10 ? `0${currentMonth}` : currentMonth;
  return `${year}-${month}-${day}`;
};

export const prepareLinkinfiedHtml = data => {
  return {
    __html: linkifyUrls(data, {
      attributes: {
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    })
  };
};

export const checkIfAllObjectKeysAreEmpty = object => {
  return (
    Object.values(object).filter(function(e) {
      return e;
    }).length === 0
  );
};

export const handleStepData = data => {
  const allSteps = { step1: {}, step2: {}, step3: {} };
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(step1Initial, key)) {
      allSteps.step1[key] = data[key];
    }
    if (Object.prototype.hasOwnProperty.call(step2Initial, key)) {
      allSteps.step2[key] = data[key];
    }
    if (Object.prototype.hasOwnProperty.call(step3Initial, key)) {
      allSteps.step3[key] = data[key];
    }
  }
  return allSteps;
};

export const overrideClassHandler = (
  className,
  componentClasses,
  parentClassNames
) => {
  return cs({
    [parentClassNames[className]]: parentClassNames[className],
    [componentClasses[className]]: !parentClassNames[className]
  });
};

export const checkIfValueExistInArrayElements = (valueTocheck, array) => {
  return (
    array && array.find(item => {
      if (item) {
        const index = item.indexOf(valueTocheck);
        if (index >= 0) return item;
      } else {
        return "";
      }
    })
  );
};

export const commissionFromProfit = profit => {
  const amount = (Number(profit) * 20) / 100;
  return Number(amount);
};

export const toTitleCase = str => {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const hideStepTab = (userRegistrationNumber, visibleAfterStep) => {
  let hideTab = true;
  if (userRegistrationNumber >= visibleAfterStep) {
    hideTab = false;
  }
  return hideTab;
};

export const truncateString = (str, wordLimit) => {
  return str.substring(0, wordLimit);
};

export const uniqueValuesInStateArray = (array, value) => {
  const currentArray = [...array];
  const index = currentArray.indexOf(value);
  if (index >= 0) {
    currentArray.splice(index, 1);
  } else {
    currentArray.push(value);
  }
  return currentArray;
};

export const removeEmptyKeysFromObject = myObj =>
  Object.keys(myObj).forEach(key => myObj[key] == null && delete myObj[key]);

export const summaryEventPageUrl = ({ team_name, company_name, id }) => {
  const url = `${team_name}-${company_name}-${id}`.replace(
    /[^a-zA-Z0-9-]/g,
    ''
  );
  return `/event-summary/${encodeURI(url)}`;
};

export const getWellbeingProviderName = ({
  session_type,
  professional_first_name,
  professional_last_name,
  booked_professional: selectedProfessional,
  service_offering
}) => {
  try {
    if (
      session_type === SPL_EVENT ||
      (professional_first_name && professional_last_name)
    ) {
      return `${professional_first_name || ''} ${professional_last_name || ''}`;
    }
    const showSmeDetails =
      service_offering && service_offering.sme.email !== openmind_sme;

    return showSmeDetails
      ? service_offering.sme.company_name
      : `${selectedProfessional.first_name} ${selectedProfessional.last_name}`;
  } catch {
    return null;
  }
};

export const getActiveTab = hash => hash.replace('#', '').replace(/%20/g, ' ');

export const isObjectEmpty = objectName => {
  return (
    objectName &&
    Object.keys(objectName).length === 0 &&
    objectName.constructor === Object
  );
};
